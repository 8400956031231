* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, sans-serif;
  /* background-color: #ffffff; */
}
.breadcrumbs {
  margin-top: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color:#d4af37;
  margin-left: 10px;
  }
.separator {
  margin: 0 5px;
  }
.breadcrumbs a{
    color:#d4af37;
    text-decoration: none;;
    }
.banner {
  width: 100%;
  height: auto;
  margin-top: 60px;
}
.banner > img {
  width: 100%;
  height: 100%;
}
.contents {
  width: 95%;
  height: auto;
  margin-top: 200px;
  margin: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 2%;
  background-color: #ffffff;
}
.details {
  width: 65%;
  height: auto;
  margin-top: 50px;
}
.details p {
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.form {
  width: 33%;
  height: auto;
  margin-top: 50px;
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
@media (max-width: 768px) and (min-width: 310px) {
  .banner {
    margin-top: 60px;
  }
  .details {
    width: 95%;
    margin: auto;
    margin-top: 30px;
  }
  .form {
    display: none;
  }
}
