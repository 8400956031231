* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: Inter, sans-serif;
}
.breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color:#d4af37;
  margin-left: 10px;
  }
.separator {
  margin: 0 5px;
  }
.breadcrumbs a{
    color:#d4af37;
    text-decoration: none;;
    }
.seasonContent {
  width: 100%;
  height: auto;
  border: 1px solid #fff;
  margin-top: 70px;
}
.banner {
  width: 100%;
  height: auto;
}
.banner img {
  width: 100%;
  height: 100%;
}
.content {
  width: 95%;
  height: auto;
  margin: auto;
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.eventInfo {
  width: 66%;
  height: auto;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.contentNav {
  width: 100%;
  height: 50px;
  margin: auto;
  position: sticky;
  top: 60px;
  padding: 10px;
  background-color: #ffffff;
  /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  z-index: 1000;
}
.customAnchor {
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.customAnchorTitle {
  font-size: 16px;
  font-weight: 600;
  color: #383737;
  /* margin-left: 10px; */
}

.details {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  margin-top: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 10px;
  /*border: 1px solid blue;
  */
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.heading {
  width: 98%;
  height: auto;
  margin: auto;
  margin-bottom: 20px;
  padding-top: 1px;
  /* border: 1px solid; */
}

.heading > h1 {
  font-size: 20px;
  text-align: left;
  margin-top: 20px;
}
.about {
  width: 98%;
  height: auto;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.about p {
  text-align: justify;
  margin-bottom: 15px;
  line-height: 22px;
  font-size: 16px;
  padding: 10px;
}
.roundsDetail {
  width: 98%;
  height: auto;
  margin: auto;
  /* margin-top: 20px; */
}
.roundsDetail p {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
}
.roundsDetail h2 {
  font-size: 17px;
  font-weight: 600;
  text-align: left;
}
.roundsTable {
  width: 100%;
  height: auto;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.roundsTable1 {
  width: 100%;
  height: auto;
  margin-top: 25px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  overflow-x: auto;
}
.tables {
  width: 80%;
  height: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
}
.tables1 {
  width: 100%;
  height: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.tables1 thead tr th {
  border: 1px solid #000000;
  background-color: #c9c4c4;
  padding: 3px;
}
.tables1 tbody tr td {
  border: 1px solid #000000;
  padding: 3px;
}

.tables thead tr th {
  border: 1px solid #000000;
  background-color: #c9c4c4;
  padding: 3px;
}
.tables tbody tr td {
  border: 1px solid #000000;
  padding: 3px;
}
.guidlines {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
}
.guidlinesHead {
  width: 98%;
  margin: auto;
  text-align: left;
  padding-top: 10px;
}
.rules {
  z-index: -10000;
}
.ant-collapse-header-text {
  font-size: 25px; /* Adjust the font size as needed */
}
.faqs {
  width: 100%;
  height: auto;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  /* border: 2px solid #d4af37; */
}
.faqHeading {
  width: 98%;
  margin: auto;
  text-align: center;
  padding-top: 10px;
  margin-bottom: 10px;
}
.faqHeading > h2 {
  font-size: 20px;
  font-weight: 600;
}
.regForm {
  width: 32%;
  height: auto;
  position: sticky;
  top: 60px;
  background-color: #ffffff;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.cardContainer {
  display: flex;
  /* align-items: center; */
  height: auto;
  padding: 5%;
  background-color: white;
  margin-top: 2%;
  flex-direction: column;
}

.cardContainer > h2 {
  text-align: left;
  margin-bottom: 2%;
}

.soloAwards {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-wrap: wrap; */
  gap: 10px;
  flex-direction: row;
}

.GroupAwards {
  display: flex;
  /* align-items: center;
  justify-content: center; */
  /* flex-wrap: wrap; */
  gap: 50px;
  flex-direction: row;
  /* width: 350px; */
}

.card {
  width: 300px;
  height: 150px;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
  margin-bottom: 2%;
}

.cardLeft {
  width: 80%;
}

.cardRight {
  width: 20%;
  /* display: flex; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.headerName {
  font-size: 20px;
  color: blue;
  font-weight: bold;
}
.certificate {
  position: absolute;
  background: #d4af37;
  transform-origin: 90% 0;
  top: 55px;
  transform: translate(30.3%) rotate(45deg);
  box-shadow: 0 0 0 999px #d4af37;
  clip-path: inset(0 -100%);
  text-align: center;
  right: 30px;
  color: white;
  padding: 3px 0px;
}

.Awardcontent {
  margin-top: 8%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  /* border: 1px solid #d4af37; */
}

.priceCategory {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  /* border: 1px solid #d4af37/; */
}
.priceCategory > p:first-child {
  /* border: 1px solid blue; */
  font-weight: 600;
}

.accordion .ant-collapse-item {
  border-top: 1px solid #eaecf0 !important;
}

.priceAmount {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  /* margin-left: 2%; */
  font-weight: 400;
}
.priceAmount > p:first-child {
  font-weight: 600;
}
.Awardcontent p {
  margin: 5px 0;
  margin-right: 5px;
}
.rules div {
  color:#000 !important;
}
.rules span div {
  font-size: 18px;
  font-weight: 600;
  color: rgb(22, 119, 255) !important;
}

@media (max-width: 767px) and (min-width: 310px) {
  .content {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
  }
  .card {
    width: 100%;
  }
  .soloAwards,
  .GroupAwards {
    display: block;
  }
  .eventInfo {
    width: 100%;
    height: auto;
  }
  .regForm {
    width: 100%;
    height: auto;
    margin-top: 10px;
    position: static;
  }
}
