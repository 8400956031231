* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Inter, sans-serif;
    /* background-color: #ffffff; */
  }
  .details {
    width: 100%;
    height: auto;
    margin-top: 50px;
  }
  .details p {
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  .breadcrumbs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    color:#d4af37;
    margin-left: 10px;
    }
  .separator {
    margin: 0 5px;
    }
  .breadcrumbs a{
      color:#d4af37;
      text-decoration: none;;
      }
  .card-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 2%;
    flex-wrap: wrap;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    text-align: center;
    margin: auto;
  }
  .sampleImage {
    width: 100%;
    height: 300px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 2%;
    border-radius: 8px;
  }
  
  .sampleImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .news-banner {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  .news-container {
    width: 30%;
    height: 320px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 2%;
    border-radius: 8px;
  }
  .news-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media (max-width: 1200px) and (min-width: 950px) {
    .sampleImage {
      width: 100%;
      /* height: 300px; */
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 2%;
      border-radius: 8px;
    }
  
    .sampleImage > image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  
  @media (max-width: 1000px) and (min-width: 700px) {
    .sampleImage {
      width: 100%;
      /* height: 300px; */
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding: 2%;
      border-radius: 8px;
    }
  
    .sampleImage > image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  
    .card {
      margin-bottom: 3%;
    }
  }
  
  @media (max-width: 500px) and (min-width: 310px) {
    .news-banner {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    .news-container {
      width: 80%;
      margin: auto;
      margin-top: 20px;
    }
  
    .card {
      margin-bottom: 3%;
    }
  }
.p4 {
    padding: 0.5rem;
}
.newstitle {
    font-weight: 500;
    gap: .5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 1.5rem;
}