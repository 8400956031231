.registrationForm > div {
  width: 95%;
  height: auto;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #ffffff;
}
.registrationForm > div > p {
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
}
.formButton {
  margin-bottom: 10px;
  width: 100%;
  background-color: #ffffff;
}
.formButton > button {
  width: 100%;
  height: 33px;
  margin: auto;
  border: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  /* background-color: #D22B2B; */
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
}
@media (max-width: 767px) and (min-width: 310px) {
  .registrationForm {
    width: 100%;
    height: auto;
  }
}
