.card-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin-top: 2%;
  flex-wrap: wrap;
}

.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  text-align: center;
  margin: auto;
}
.sampleImage {
  width: 100%;
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2%;
  border-radius: 8px;
}
.dance-dynamite-desc p{text-align: justify !important;}


.sampleImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.dance-dynamite-desc {
  text-align: start;
  margin-bottom: 1%;
  padding: 0px 15px;
}

.dance-dynamite-desc > p {
  text-align: start;
  margin-bottom: 1%;
  padding: 0px 15px;
}

.seasonContainer {
  margin-top: 2%;
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.no-style {
  color: inherit;
  text-decoration: none;
}
.news-banner {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 30px;
}
.news-container {
  width: 30%;
  height: 320px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2.5%;
  border-radius: 8px;
}
.news-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1200px) and (min-width: 950px) {
  .sampleImage {
    width: 100%;
    /* height: 300px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 2%;
    border-radius: 8px;
  }

  .sampleImage > image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  .sampleImage {
    width: 100%;
    /* height: 300px; */
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 2%;
    border-radius: 8px;
  }

  .sampleImage > image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
  }

  .dance-dynamite-desc > p {
    margin-bottom: 2%;
  }

  .card {
    margin-bottom: 3%;
  }
}

@media (max-width: 500px) and (min-width: 310px) {
  .news-banner {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }
  .news-container {
    width: 80%;
    margin: auto;
    margin-top: 20px;
  }

  .dance-dynamite-desc > p {
    margin-bottom: 5%;
  }

  .card {
    margin-bottom: 3%;
  }
}

.carousel-images {
  position: relative;
  border-radius: 10px;
  height: auto;
  max-width: 650px;
  margin: auto;
  overflow: hidden;
}
.carousel-images img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: #d4af37 solid 2px;  
}
.slide_direction {
  display: flex;
  justify-content: space-between;
}
.left,
.right {
  background-color: #d4af37;
  color: #fff;
  padding: 5px 8px 8px 8px;
  margin: 0 20px;
  border-radius: 50%;
  position: absolute;
  display:none;
  top: 0;
  bottom: 0;
  margin: auto 10px;
  height: 25px;
  width: 25px;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
.carousel {
  position: relative;
}
.play-pause-button {
  position: absolute;
  bottom: 20px;
  right: 20px;
  background: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  z-index: 10;
}

.play-pause-button:hover {
  background: rgba(255, 255, 255, 0.9);
}
.carousel-indicator {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}
.dot {
  background-color: #333;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.active {
  background-color: #d4af37;
}