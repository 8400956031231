.navbar {
  width: 100%;
  height: 60px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 1000;
}
.logo {
  width: 35%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

img.scrollimg {
  width: 40%;
  height: auto;
  position: absolute;
  top: -10px;
  left: 60px;
}
img.scrolled {
  width: 65%; /* Adjust the size when scrolled */
  height: auto;
  position: absolute;
  top: 0px;
  left: 0px;
}

.social {
  width: 12%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 10px;
}
.social p {
  font-size: 25px;
  cursor: pointer;
  font-weight: 600;
  /* color: #383737; */
}
.social p a {
  text-decoration: none;
  /* color: #383737; */
  color: #d4af37;
}
.social p a:hover {
  color: #383737;
}

.navMenu {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.navMenu p {
  cursor: pointer;
  font-weight: 600;
  /* color: #383737; */
  text-decoration: none;
}
.inactive {
  text-decoration: none;
  color: #d4af37;
}
.active {
  color: black;
  text-decoration: none;
}
@media (max-width: 1023px) and (min-width: 1001px) {
  img.scrollimg {
    width: 40%;
    height: auto;
    position: absolute;
    top: 10px;
    left: 60px;
    /* border: 1px solid #d4af37; */
  }
  img.scrolled {
    width: 100%; /* Adjust the size when scrolled */
    height: auto;
    position: absolute;
    top: 10px;
    left: 0px;
  }
}

@media (max-width: 1000px) and (min-width: 810px) {
  img.scrollimg {
    width: 40%;
    height: auto;
    position: absolute;
    top: 10px;
    left: 60px;
    /* border: 1px solid #d4af37; */
  }
  img.scrolled {
    width: 100%; /* Adjust the size when scrolled */
    height: auto;
    position: absolute;
    top: 10px;
    left: 0px;
  }
}
@media (max-width: 809px) and (min-width: 610px) {
  img.scrollimg {
    width: 40%;
    height: auto;
    position: absolute;
    top: 10px;
    left: 60px;
    /* border: 1px solid #d4af37; */
  }
  img.scrolled {
    width: 100%; /* Adjust the size when scrolled */
    height: auto;
    position: absolute;
    top: 10px;
    left: 0px;
  }
}
@media (max-width: 609px) and (min-width: 310px) {
  .logo {
    width: 30%;
    height: 100%;
    position: relative;
  }
  img.scrollimg {
    width: 80%;
    height: auto;
    position: absolute;
    top: 0px;
    left: 10px;
    /* border: 1px solid #d4af37; */
  }
  .navMenu {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .social {
    display: none;
  }
  .navMenu p {
    font-weight: 13px;
    cursor: pointer;
    font-weight: 600;
    /* border: 1px solid #d4af37; */
    /* color: #383737; */
  }
  img.scrolled {
    width: 100%; /* Adjust the size when scrolled */
    height: auto;
    position: absolute;
    top: 20px;
    left: 0px;
  }
}
