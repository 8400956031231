.footer {
  padding: 20px;
  background-color: #d4af37;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.footersocial {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.footersocial p {
  margin-right: 10px;
  font-size: 25px;
}
.footersocial p a {
  color: #fff;
}
.footeremail a {
  color: #fff;
  text-decoration: none;
}

@media (max-width: 699px) and (min-width: 310px) {
  .footer {
    display: block;
  }
  .footeremail {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .footersocial {
    justify-content: center;
  }
}
